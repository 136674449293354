<template>
   <SkeletonDoubleSelect v-if="loadingContent" />
   <div class="card card-custom" v-else>
      <div class="d-flex mb-3" :class="isMobile() ? 'flex-column' : ''">
         <b-form-group label="Établissement" label-for="customerSelect" class="m-5" :class="isMobile() ? '' : 'w-50'" data-app>
            <CustomSelect
               @input="addData()"
               id="customerSelect"
               :options="optionsCustomer"
               v-model="filterCustomer"
               searchable
               labelSearchPlaceholder="Rechercher un établissement"
               labelNotFound="Aucun résultat pour"
            />
         </b-form-group>
         <b-form-group label="Thème de formation" label-for="themeSelect" class="m-5" :class="isMobile() ? '' : 'w-50'" data-app>
            <CustomSelect @input="addData()" id="themeSelect" :options="optionsTheme" v-model="filterTheme" />
         </b-form-group>
      </div>
      <p class="font-weight-bold" style="text-align: center">
         Seuls les établissements disposants d'un effectif réel sont pris en compte dans cette statistique.
      </p>
      <div v-if="currentUser.role !== 'Utilisateur'">
         <b-table
            v-if="showTable"
            id="statsTable"
            :fields="fields"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :hide-default-footer="true"
            :busy="loading"
            show-empty
            responsive
         >
            <template v-slot:cell(customer.code)="data">
               <!-- <template v-slot:item.customer.code="{ item }"> -->
               {{ data.item.customer.name }}
            </template>
            <template v-slot:cell(effectif_reel)="data">
               <i class="fas fa-users mr-1"></i>
               {{ data.item.effectif_reel }}
            </template>
            <template v-slot:cell(statut)="data">
               <v-chip :style="getStatut(data.item.taux_atteint, data.item.taux_alert, 'color')">
                  {{ getStatut(data.item.taux_atteint, data.item.taux_alert, 'text') }}
               </v-chip>
            </template>
            <template v-slot:cell(taux_atteint)="data"> {{ data.item.taux_atteint.toString().replace('.', ',') }}% </template>
            <template v-slot:cell(objectif_effectif)="data">
               <i class="fas fa-users mr-1"></i>
               {{ data.item.objectif_effectif }}
            </template>
            <template v-slot:cell(effectif_forme)="data">
               <router-link
                  style="color: black"
                  v-if="data.item.effectif_forme > 0"
                  :to="{ name: 'statistiques.trained', params: { customer_id: data.item.customer.id, theme_id: data.item.theme_id } }"
               >
                  <i class="fas fa-users mr-1"></i>
                  {{ data.item.effectif_forme }}
               </router-link>
               <div v-else>
                  <i class="fas fa-users mr-1"></i>
                  {{ data.item.effectif_forme }}
               </div>
            </template>
            <template v-slot:cell(action)="data">
               <router-link
                  v-if="data.item.effectif_forme > 0"
                  :to="{
                     name: 'statistiques.trained',
                     params: {
                        customer_id: data.item.customer.id,
                        theme_id: data.item.theme_id,
                        selectParams: { customer_id: filterCustomer, theme_id: filterTheme }
                     }
                  }"
               >
                  <b-button class="btn btn-icon btn-circle btn-light btn-sm" v-b-tooltip.hover="'Voir le détail'">
                     <inline-svg src="/media/svg/icons/General/Visible.svg"></inline-svg>
                  </b-button>
               </router-link>
            </template>
            <template #empty="scope">
               <div class="text-center my-2">
                  <span>Aucun résultat.</span>
               </div>
            </template>
            <template #table-busy>
               <div class="text-center my-2">
                  <strong>Chargement...</strong>
               </div>
            </template>
         </b-table>
         <b-pagination
            v-if="showTable"
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
            aria-controls="statsTable"
            align="center"
         ></b-pagination>
         <div v-if="displayWarning" class="d-flex flex-column justify-content-center align-items-center mb-5">
            <p class="text-center">Veuillez sélectionner un établissement et / ou un thème de formation</p>
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LIST_STATISTIQUES } from '@/core/services/store/api/statistique';
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import { LIST_THEMES_BY_CUSTOMER_ID } from '@/core/services/store/api/theme';
import { LIST_CUSTOMERS_WITH_FILLIALE } from '@/core/services/store/api/customer';
import SkeletonDoubleSelect from '@/view/content/skeleton/SkeletonDoubleSelect.vue';
import VSelect from '@alfsnd/vue-bootstrap-select';

export default {
   props: {
      selectParams: {
         type: Object,
         required: false
      }
   },
   data() {
      return {
         loading: true,
         loadingContent: true,
         currentPage: 1,
         sortBy: 'start',
         perPage: 10,
         items: [],
         filterCustomer: { text: 'Sélectionnez un établissement', value: '' },
         optionsCustomer: [],
         filterTheme: { text: 'Sélectionnez un thème de formation', value: '' },
         optionsTheme: [],
         showTable: false,
         displayWarning: true,
         fields: [
            { label: this.$t('STATISTIQUES.GENERAL.THEME'), key: 'theme' },
            { label: this.$t('STATISTIQUES.GENERAL.CUSTOMER'), sortable: true, key: 'customer.code' },
            { label: this.$t('STATISTIQUES.GENERAL.EFFECTIF_REEL'), sortable: true, key: 'effectif_reel' },
            { label: this.$t('STATISTIQUES.GENERAL.OBJECTIF_EFFECTIF'), sortable: true, key: 'objectif_effectif' },
            { label: this.$t('STATISTIQUES.GENERAL.EFFECTIF_FORME'), sortable: true, key: 'effectif_forme' },
            { label: this.$t('STATISTIQUES.GENERAL.TAUX_ATTEINT'), sortable: true, key: 'taux_atteint' },
            { label: this.$t('STATISTIQUES.GENERAL.STATUT'), key: 'statut', align: 'center' },
            { label: '', key: 'action', sortable: false, align: 'center' }
         ]
      };
   },
   components: {
      CustomSelect: VSelect,
      SkeletonDoubleSelect
   },
   mounted() {
      this.$store.dispatch(SET_PAGETITLE, this.$t('MENU.STATISTIQUES'));
      this.$store.dispatch(LIST_CUSTOMERS_WITH_FILLIALE, this.$store.getters.currentUser.customer.id).then((result) => {
         result.forEach((customer) => {
            this.optionsCustomer.push({
               text: customer.name + ' (' + customer.code + ')',
               value: customer.id
            });
            this.optionsCustomer.sort((a, b) => a.text.localeCompare(b.text));
         });
         this.optionsCustomer.unshift({
            text: 'Tous les établissements',
            value: ''
         });
      });
      this.$store.dispatch(LIST_THEMES_BY_CUSTOMER_ID, this.$store.getters.currentUser.customer.id).then((response) => {
         response.forEach((theme) => {
            this.optionsTheme.push({
               text: theme.name,
               value: theme.id
            });
            this.optionsTheme.sort((a, b) => a.text.localeCompare(b.text));
         });
         this.optionsTheme.unshift({
            text: 'Tous les thèmes de formation',
            value: ''
         });
      });
   },
   methods: {
      getStatut(taux_atteint, taux_alert, format) {
         if (taux_atteint <= taux_alert) {
            if (format === 'color') {
               return 'background-color: #f44336; color: white;';
            } else {
               return 'Alerte';
            }
         } else if (taux_atteint > taux_alert && taux_atteint < 100) {
            if (format === 'color') {
               return 'background-color: #ff9800; color: white;';
            } else {
               return 'Attention';
            }
         } else {
            if (format === 'color') {
               return 'background-color: #4caf50; color: white;';
            } else {
               return 'Ok';
            }
         }
      },
      async addData() {
         this.items = [];
         this.loading = true;
         const filter = {
            customer: this.filterCustomer.value,
            theme: this.filterTheme.value
         };
         if (filter.customer === '') {
            filter.customer = null;
         }
         if (filter.theme === '') {
            filter.theme = null;
         }
         const data = {
            customerId: filter.customer,
            themeId: filter.theme
         };
         if (filter.customer !== null || filter.theme !== null) {
            await this.$store.dispatch(LIST_STATISTIQUES, { data }).then((response) => {
               this.items = response.data;
               this.loading = false;
            });
         }
      },
      displayTable() {
         if (this.filterTheme.value || this.filterCustomer.value) {
            this.showTable = true;
            this.displayWarning = false;
         } else {
            this.showTable = false;
            this.displayWarning = true;
         }
      }
   },
   computed: {
      ...mapGetters({
         currentUser: 'currentUser',
         statistiques: 'statistique',
         themes: 'listThemes',
         customer: 'listCustomersWithFilliale'
      })
   },
   updated() {
      this.displayTable();
   },
   watch: {
      optionsTheme() {
         if (this.optionsTheme.length > 0 && this.optionsCustomer.length > 0) {
            this.loadingContent = false;
            if (this.selectParams && this.selectParams.customer_id && this.selectParams.theme_id) {
               this.filterCustomer = this.selectParams.customer_id;
               this.filterTheme = this.selectParams.theme_id;
               this.addData();
            }
         }
         if (this.selectParams && this.selectParams.theme_id && this.selectParams.customer_id === '') {
            this.filterTheme = this.selectParams.theme_id;
            this.addData();
         }
      },
      optionsCustomer() {
         if (this.optionsTheme.length > 0 && this.optionsCustomer.length > 0) {
            this.loadingContent = false;
            if (this.selectParams && this.selectParams.customer_id && this.selectParams.theme_id) {
               this.filterCustomer = this.selectParams.customer_id;
               this.filterTheme = this.selectParams.theme_id;
               this.addData();
            }
         }
         if (this.selectParams && this.selectParams.customer_id && this.selectParams.theme_id === '') {
            this.filterCustomer = this.selectParams.customer_id;
            this.addData();
         }
      }
   }
};
</script>

<style>
tr > td:last-of-type {
   padding: 0 !important;
   margin: 0 !important;
}

.customCell {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 3rem;
}
</style>
